<template>
    <v-container class="page-plan">
    <v-col class="pb-16 mb-16">
        <v-row>
            <v-col cols="12"
                class="text-center"
            >
                <h1 class="display-1">{{$t('plan.title')}}</h1>
                <!-- subtitle -->
                <v-alert
                color="primary"
                >{{$t(`plan.${reason}`)}}</v-alert>
            </v-col>
            <v-col cols="12" lg="6">
                <v-card outlined class="light">
                    <v-card-title>
                        <h2>Free</h2>
                        
                        <v-spacer />
                        0$
                    </v-card-title>
                    <v-card-text>
                        <p>
                            {{ $t('plan.free_desc') }}
                        </p>
                        <ul
                            style="list-style: none; padding: 0; margin: 0;"
                        >
                            <li>
                                <v-icon color="primary">mdi-check</v-icon>
                                5 {{$t('plan.projects')}}
                            </li>

                            <li>
                                <v-icon color="primary">mdi-check</v-icon>
                                {{$t('plan.ai_filter')}}
                            </li>
                        </ul>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" :disabled="true" block @click="gtagEvent">Free (current)</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6" v-if="false">
                <v-card outlined class="light">
                    <v-card-title>
                        <h2>Basic</h2>
                        <v-spacer />
                        19.99$/{{ $t('terms.month') }}
                    </v-card-title>
                    <v-card-text class="">
                        <!-- 14 days free -->
                        <v-alert
                            type="primary"
                            border="left"
                            prominent
                            dense
                            class="mb-4"
                        >
                            {{$t('plan.free_trial')}}
                        </v-alert>
                        <p>
                            {{ $t('plan.basic_desc') }}
                        </p>
                        <ul
                            style="list-style: none; padding: 0; margin: 0;"
                        >
                            <li>
                                <v-icon color="primary">mdi-check</v-icon>
                                10 {{$t('plan.projects')}}
                            </li>
                            <li>
                                <v-icon color="primary">mdi-check</v-icon>
                                {{$t('plan.ai_filter')}}
                            </li>

                            <li>
                                <v-icon color="primary">mdi-check</v-icon>
                                {{$t('plan.ai_voice_generation')}}
                                <!-- tooltip information -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            small
                                            color="primary"
                                            v-on="on"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>
                                        {{ $t('tooltip.ai_voice_generation') }}
                                    </span>
                                </v-tooltip>
                            </li>
                            <li>
                                <v-icon color="primary">mdi-check</v-icon>
                                {{$t('plan.translation')}}
                            </li>
                        </ul>

                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" :href="`https://buy.stripe.com/eVa28H7WBcxf3dKdQS?prefilled_email=${$auth.user.email}`" target="_blank" block @click="gtagEvent">19.99$/{{$t('terms.month')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6">
                <v-card outlined class="light">
                    <v-card-title>
                        <h2 class="primary--text">Pro</h2>
                        <v-spacer />
                        12,99€/{{ $t('terms.month') }}
                    </v-card-title>
                    <v-card-text>
                        <p>
                            {{ $t('plan.pro_desc') }}
                        </p>
                        <ul
                            style="list-style: none; padding: 0; margin: 0;"
                        >
                            <li>
                                <v-icon color="primary">mdi-check</v-icon>
                                {{$t('plan.unlimited_projects')}}
                            </li>
                            <li>
                                <v-icon color="primary">mdi-check</v-icon>
                                {{$t('plan.studio_mode')}} 
                                <!-- tooltip information -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            small
                                            color="primary"
                                            v-on="on"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>
                                        {{ $t('tooltip.studio_mode') }}
                                    </span>
                                </v-tooltip>
                            </li>
                            <li>
                                <v-icon color="primary">mdi-check</v-icon>
                               {{ $t('plan.audio_bwf') }}
                               <!-- tooltip information -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            small
                                            color="primary"
                                            v-on="on"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>
                                        {{ $t('tooltip.audio_bwf') }}
                                    </span>
                                </v-tooltip>
                            </li>
                        </ul>
                    </v-card-text>
                    <v-card-actions>
                        <!-- <v-btn color="primary" :href="`https://buy.stripe.com/8wM28HdgV7cV5lS8wz?prefilled_email=${$auth.user.email}`" target="_blank" block @click="gtagEvent">49.99$/{{$t('terms.month')}}</v-btn> -->
                        <v-btn color="primary" :href="`https://buy.stripe.com/eVa00zccR1SB7u0aEL?prefilled_email=${$auth.user.email}`" target="_blank" block @click="gtagEvent">12,99€/{{$t('terms.month')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col class="pb-16 mb-16">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">{{ $t('plan.feature') }}</th>
                                <th class="text-left">{{ $t('plan.free') }}</th>
                                <!-- <th class="text-left">{{ $t('plan.basic') }}</th> -->
                                <th class="text-left">{{ $t('plan.pro') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ $t('plan.projects') }}</td>
                                <td>5</td>
                                <!-- <td>10</td> -->
                                <td>{{ $t('plan.unlimited') }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('plan.speech_to_text') }}</td>
                                <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td>
                                <!-- <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td> -->
                                <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('plan.ai_filter') }}</td>
                                <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td>
                                <!-- <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td> -->
                                <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('plan.translation') }}</td>
                                <td>
                                    <v-icon color="grey">mdi-close</v-icon>
                                </td>
                                <!-- <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td> -->
                                <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('plan.ai_voice_generation') }}</td>
                                <td>
                                    <v-icon color="grey">mdi-close</v-icon>
                                </td>
                                <!-- <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td> -->
                                <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('plan.studio_mode') }}</td>
                                <td>
                                    <v-icon color="grey">mdi-close</v-icon>
                                </td>
                                <!-- <td>
                                    <v-icon color="grey">mdi-close</v-icon>
                                </td> -->
                                <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('plan.audio_bwf') }}</td>
                                <td>
                                    <v-icon color="grey">mdi-close</v-icon>
                                </td>
                                <!-- <td>
                                    <v-icon color="grey">mdi-close</v-icon>
                                </td> -->
                                <td>
                                    <v-icon color="primary">mdi-check</v-icon>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <v-icon color="grey"></v-icon>
                                </td>
                                <!-- <td>
                                    <v-icon color="grey"></v-icon>
                                </td> -->
                                <td>
                                    <v-icon color="primary"></v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-col>
</v-container>
</template>

<script>
export default {
    name:'PlanView',
    props:{
        reason:{
            type:String,
            default:'subtitle'
        }
    },
    methods:{
        gtagEvent(){
            window.gtag('event', 'click_stripe')
        }
    }
}
</script>

<style>
.page-plan {
    overflow:scroll;
}
</style>