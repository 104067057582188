import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/en-gb'
// import VueIntro from 'vue-introjs';
// Vue.use(VueIntro);
// import 'intro.js/introjs.css';
import Hotjar from 'vue-hotjar'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// init event bus
export const EventBus = new Vue();

Vue.prototype.$bus = EventBus;

Vue.use (Hotjar, {
  id: '3403295' // Hotjar Site ID
})

Sentry.init({
  Vue,
  dsn: "https://0ad4382e059f4c2c887a3780aa37aab1@o569116.ingest.sentry.io/4504826655342592",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "api.voxdub.com","api-2.voxdub.com","app.voxdub.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// import VueGtag from "vue-gtag";

// Vue.use(VueGtag, {
//   config: { id: "G-B6S8PNCRSS" }
// }, router);

import Strapi from "strapi-sdk-js";

const strapi = new Strapi({
  url: process.env.VUE_APP_API_URI || "http://localhost:1337"
});

Vue.prototype.$strapi = strapi;
Vue.prototype.$sentry = Sentry;

moment().locale('fr')

import * as contentful from 'contentful'
Vue.prototype.$contentful = contentful

Vue.config.productionTip = false

Vue.prototype.$moment = moment

// Import the Auth0 configuration
// Should not import the named export 'clientId' (imported as 'clientId') from default-exporting module (only default export is available soon)
import { domain, clientId } from '../auth_config.json';

// Import the plugin here
import { Auth0Plugin } from './auth';
import axios from 'axios';

import i18n from './i18n'

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    // if appState target url contain t param
    if (appState && appState.targetUrl && appState.targetUrl.indexOf('t=') > -1) {
      // get the t param
      if(!localStorage.getItem('t')){
        const t = appState.targetUrl.split('t=')[1]
        localStorage.setItem('t', t)
        axios.post(`${process.env.VUE_APP_API_URI}/api/affiliate-logs`, {code: t, type:'signup'})
      }
    }
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
