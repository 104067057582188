import Vue from 'vue';

import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

import { authGuard } from '../auth/authGuard';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=> import(/* webpackChunkName: "newproject" */ '../views/NewProjectView.vue'),
    beforeEnter: authGuard,
  },
  {
    path:'/help',
    name:'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/HelpView.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/project',
    component: HomeView,
    beforeEnter: authGuard,
  },
  {
    path:'/community',
    name:'community',
    component: () => import(/* webpackChunkName: "comunity" */ '../views/CommunityView.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/t/:affiliate',
    name: 'home',
    component: HomeView,
    beforeEnter: authGuard
  },
  {
    path: '/plan',
    name: 'plan',
    component: () => import(/* webpackChunkName: "plan" */ '../views/PlanView.vue'),
    beforeEnter: authGuard
  },
  {
    path:'/login',
    name:'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/workspaces',
    name: 'workspaces',
    component: () => import(/* webpackChunkName: "workspaces" */ '../views/WorkspacesView.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: () => import(/* webpackChunkName: "workspaces" */ '../views/ThankYouView.vue'),
    beforeEnter: authGuard
  },
  {
    path:'/settings',
    name:'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue'),
    beforeEnter: authGuard
  },{
    path:'/test',
    name:'test',
    component: () => import(/* webpackChunkName: "test" */ '../views/TestLayout.vue'),
  },
  {
    path:'/testmic',
    name:'test',
    component: () => import(/* webpackChunkName: "test" */ '../views/TestMic.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
