import Vue from 'vue'
import Vuex from 'vuex'
import Strapi from "strapi-sdk-js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cinemaMode: false,
    user:{
      id: 1,
      projects: [],
    },
    region: localStorage.getItem('region') || 'eu',
    tasks: []
  },
  getters: {
    GET_USER: state => {
      return state.user;
    }
  },
  mutations: {
    SET_TASKS: (state, tasks) => {
      state.tasks = tasks;
    },
    SET_USER_PROJECTS: (state, projects) => {
      state.user.projects = projects;
    },
    SET_REGION: (state, region) => {
      state.user.region = region;
      localStorage.setItem('region', region);
      let api_url = '';
      if(region == 'eu')
      {
        api_url = process.env.VUE_APP_API_URI || "http://api-2.voxdub.com";
      }else{
        api_url = 'https://api-us.voxdub.com';
      }
      Vue.prototype.$strapi = new Strapi({
        url: api_url
      });
    },
    SET_CINEMA_MODE: (state, cinemaMode) => {
      state.cinemaMode = cinemaMode;
    }
  },
  actions: {
    // set user projects 
    SET_USER_PROJECTS: ({commit}, projects) => {
      commit('SET_USER_PROJECTS', projects);
    },
    setRegion: ({commit}, region) => {
      commit('SET_REGION', region);
    },
    setCinemaMode: ({commit}, cinemaMode) => {
      commit('SET_CINEMA_MODE', cinemaMode);
    },
    setTasks: ({commit}, tasks) => {
      commit('SET_TASKS', tasks);
    }

  },
  modules: {
  }
})
