import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
// primary -webkit-gradient(linear, left top, right top, from(#dec880), color-stop(27%, #fff), color-stop(57%, #ff3980), to(#827ded))
export default new Vuetify({
    theme:{
        dark:true,
        themes:{
            dark:{
                // gradient
                primary: '#927EBC',
                dark:'#131416'
            }
        }
    }
});
