<template>
  <v-app>
    <v-navigation-drawer app color="dark" dark mini fixed style=" overflow: visible!important;transform:translateX(0%);"
      class="pb-6" :mini-variant="drawer" :permanent="!drawer" v-if="false" width="300">

      <!-- Logo img header -->
      <v-list-item class="px-3 mt-6">
        <v-list-item-avatar size="35">
          <v-img alt="Logo" cover src="/icons/large-icon.png" />
        </v-list-item-avatar>
      </v-list-item>
      <v-list>
        <v-list-item-group>
          <v-list-item v-for="(item, i) in items" :to="item.to || ''" :title="item.title" exact
            exact-active-class="primary--text" @click="item.title === 'logout' ? logout() : ''" :key="i" link>
            <v-list-item-icon>
              <v-icon :color="item.title === 'logout' ? 'error' : ''">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t(`menu.${item.title}`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- Arrow button for activate drawer -->
      <v-card v-if="!drawer">
        <v-card-text class="text-center">
          <!-- progress bar for limit of free plan -->
          <h3 class="mb-3">
            {{ $auth.user?.role ? $auth.user?.role.toUpperCase() : 'Free' }}
            <!-- info buble -->
            <v-tooltip v-if="!$auth.user?.role" bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="primary" v-on="on">
                  mdi-information
                </v-icon>
              </template>
              <span>
                {{ $t('tooltip.free_plan') }}
              </span>
            </v-tooltip>
          </h3>
          <v-progress-linear :value="user.projects.length * 100 / 5" color="primary" height="15">
          </v-progress-linear>

          <span class="white--text ">
            {{ user.projects.length }}/{{ nbOfProjectsBasedOnPlan }} {{ $t('label.projects_created') }}
          </span>
          <v-btn elevation="0" v-if="!$auth.user?.role" block class="mt-3" dense primary to="/plan">
            <span class="mr-2">
              <v-icon color="primary">
                mdi-account
              </v-icon>
            </span>
            <span>{{ $t('button.upgrade_to_basic') }}</span>
          </v-btn>

        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <v-main>

      <template>
        <v-dialog v-model="choiceRegion" max-width="500">
          <ChangeRegion @close="choiceRegion = false" />
        </v-dialog>
      </template>
      <!-- alert if user exceed nbOfProjectsBasedOnPlan -->
      <v-alert v-if="user.projects.length >= nbOfProjectsBasedOnPlan" class="mb-0" type="error" dark dismissible>
        {{ $t('error.limit_reached') }}
        <v-btn text to="/plan">
          {{ $t('button.upgrade_to_pro') }}
        </v-btn>
      </v-alert>

      <v-container fluid class="pa-0 ma-0">
        <router-view />
      </v-container>

      <v-bottom-navigation v-if="!cinemaMode" v-model="value" fixed class="v-bottom-navigation--absolute">
        <v-btn v-for="(item, i) in items" :key="i" :to="item.to || ''" :title="item.title" :value="item.title" exact
          exact-active-class="primary--text" @click="item.title === 'logout' ? logout() : ''">
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
        <v-btn v-if="!isPro" to="/plan">
          <v-icon color="primary" class="blink">
            mdi-star
          </v-icon>
          PRO
        </v-btn>
      </v-bottom-navigation>
    </v-main>
    <IApp v-if="iap && isMobile" />
    <v-dialog v-model="popupPlan" max-width="" class="pa-0 ma-0">
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-btn icon @click="popupPlan = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <PlanView />
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import { Capacitor } from '@capacitor/core';
import moment from 'moment';
import PlanView from './views/PlanView.vue';
export default {
  name: "App",
  components: {
    ChangeRegion: () => import('./components/ChangeRegion.vue'),
    IApp: () => import('./views/IAppView.vue'),
    PlanView
  },
  computed: {
    isMobile() {
      return Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android';
    },
    ...mapState(["user", "region", "cinemaMode"]),
    nbOfProjectsBasedOnPlan() {
      if (this.$auth.user?.role == "basic") {
        return 10;
      }
      else if (this.$auth.user?.role == "pro") {
        return "∞";
      }
      return 5;
    },
    isPro() {
      return this.$auth.user?.role == "pro" || this.$auth.user?.role == "basic";
    }
  },
  data: () => ({
    drawer: true,
    popupPlan: false,
    value: "new_project",
    // iap: localStorage.getItem('iap') ? false : true,
    iap: true,
    moment: moment,
    choiceRegion: false,
    items: [
      {
        title: "new_project",
        icon: "mdi-filmstrip",
        to: "/"
      },
      {
        title: "workspace",
        icon: "mdi-folder",
        to: "/workspaces"
      },
      // {
      //   title: "catalog",
      //   icon: "mdi-account-group",
      //   to: "/community"
      // },
      {
        title: "profile",
        icon: "mdi-account",
        to: "/settings"
      }
    ],
  }),
  mounted() {
    // debug

    if(!this.$auth.user?.role)
    {
      if(!localStorage.getItem('firstTime') || parseInt(localStorage.getItem('firstTime')) > 5)
      {
        localStorage.setItem('firstTime',1);
        this.popupPlan = true;
      }else{
        localStorage.setItem('firstTime',parseInt(localStorage.getItem('firstTime'))+1);
        if(parseInt(localStorage.getItem('firstTime')) > 5)
        {
          this.popupPlan = true;
        }
      }
    }
    // !this.iap ? localStorage.setItem('iap', true) : ''; 
    this.drawer = this.$vuetify.breakpoint.mobile ? false : true;
    if (this.region) {
      this.choiceRegion = false;
      this.$store.dispatch('setRegion', this.region)
    } else {
      this.choiceRegion = true;
    }
    // detect lang 
    var lang = navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage;
    lang = lang.substr(0, 2);
    lang = lang.toLowerCase();
    if (lang == "fr") {
      this.$i18n.locale = "fr";
    }
    else {
      this.$i18n.locale = "en";
    }
    // event bus listen
    this.$bus.$on("updateProjects", () => {
      this.getUserProjects();
    });
    this.$nextTick(() => {
      this.getUserProjects();
    });
    window.addEventListener(
      "touchmove",
      function (event) {
        if (event.scale !== 1) {
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      },
      { passive: false }
    );
  },
  watch: {
    "$auth.user.sub": function () {
      this.getUserProjects();
    }
  },
  methods: {
    getUserProjects() {
      if (!this.$auth.user.sub)
        return;
      this.$strapi.find("workspaces", {
        populate: "*",
        filters: {
          user: this.$auth.user.sub,
          public: false
        }
      }).then(response => {
        this.$store.dispatch("SET_USER_PROJECTS", response.data);
      }).catch(error => {
        console.log(error);
      });
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Syne:wght@400;500;600;700;800&family=Mountains+of+Christmas:wght@700&display=swap');
$body-font-family: 'Be Vietnam Pro', sans-serif;
$title-font: 'Syne', sans-serif;

.christmas {
  font-family: 'Mountains of Christmas', cursive;
}


.v-application {
  font-family: $body-font-family, sans-serif !important;

  .title, .v-toolbar__title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}

.theme--dark.v-application {
  //gradient with #000212 and white
  background: #000212;
}


.v-navigation-drawer--close {
  visibility: visible !important;
}

nav.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--mini-variant.v-navigation-drawer--open.theme--dark.dark,
.v-bottom-navigation {
  bottom: 5px !important;
  box-shadow: inset 0px 1px 0px rgba(255,255,255, .1)!important;
  width: 99% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  height: 50px !important;
  background-color: rgba(255, 255, 255, .1) !important;
  -webkit-backdrop-filter: brightness(120%) contrast(100%) saturate(100%) blur(15px) hue-rotate(0deg);
  backdrop-filter: brightness(120%) contrast(100%) saturate(100%) blur(15px) hue-rotate(0deg);
  border-radius: 10px;
  z-index:1000!important;
}

.theme--dark.v-tabs > .v-tabs-bar, .theme--dark.v-data-table, .v-application .dark,.menu {
  background-color: transparent !important;
  height:20px;
}

.v-tab
{
  font-size:.75em!important;
}

.v-application .dark{
  background-color: #000212 !important;
}

.v-toolbar {
    backdrop-filter: brightness( 120% ) contrast( 100% ) saturate( 100% ) blur( 15px ) hue-rotate( 0deg );
    border-radius: 999px 999px 999px 999px;
    box-shadow: inset 0px 1px 0px rgba(255,255,255, .1)!important;
}

.v-toolbar__content .v-btn--round, .v-btn:not(.v-btn--round).v-size--x-small {
    border-radius: 5px;
    margin-left:2px;
    margin-right:2px;
    height: 30px !important;
}

.theme--dark.v-toolbar.v-sheet {
  margin:0px;
  -webkit-backdrop-filter: brightness(120%) contrast(100%) saturate(100%) blur(15px) hue-rotate(0deg);
  backdrop-filter: brightness(120%) contrast(100%) saturate(100%) blur(15px) hue-rotate(0deg);
  border-radius: 0px
}

.v-navigation-drawer__border {
  display: none;
}


.v-sheet, .v-card{
  // background-color: rgba(255, 255, 255, 1) !important;
  backface-visibility: hidden;
  background-attachment: fixed;
}

.v-main__wrap{
  overflow: scroll;
}


.video-preview:hover {
  background-image: linear-gradient(90deg, #8468923d, #e0c9eb36);
  padding: 1px;
  border-radius: 8px;
  height: 100%;
  transition: all 0.3s ease-in;
}

/* animation */
.video-preview {
  background-image: none;
  padding: 5px;
  border-radius: 8px;
  height: 100%;
  margin-bottom: 1em;
  transition: all 1s ease-out;
}

.introjs-arrow.top {
  display: none !important;
}

.v-btn > .v-btn__content .v-icon {
    filter: contrast(0.5)!important;
}

.v-menu__content {
    z-index: 1000!important;
}

// blink animation
.blink {
  animation: blinker 1s linear infinite;
}

</style>